export default [
  {
    type: "selection",
    width: 38,
    align: "center",
  },
  {
    title: "Designation",
    key: "Designation",
    minWidth: 80,
    // sortable: true,
  },
  {
    title: "Department",
    key: "Department",
    minWidth: 80,
    // sortable: true,
  },
  {
    title: "Educational Qualifications",
    key: "EducationalQualifications",
    // sortable: true,
    minWidth: 80,
  },
  {
    title: "Salary Range",
    key: "SalaryRange",
    // sortable: true,
    minWidth: 80,
  },
  // {
  //   title: "Skill Required",
  //   key: "SkillRequired",
  //   // sortable: true,
  //   minWidth: 180,
  // },


   {
    title: "Experience Required",
    key: "ExperienceRequired",
    // sortable: true,
    minWidth: 80,
  },
  {
    title: "Salary Range",
    key: "SalaryRange",
    // sortable: true,
    minWidth: 80,
  },
];
